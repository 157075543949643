.page-header {
  height: 310px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  img {
    height: 710px;
    width: 100%;
    position: absolute;
  }
}
