.packages-modal {
  //width: 90%;
  .modal-content {
    width: 90vw;
    max-width: 1060px;
    margin-left: 50%;
    transform: translate(-50%);
  }
  .modal-body {
    padding-top: 0;

    .join-text {
      margin-bottom: 30px;
      font-size: 19px;
    }
  }
  .packages {
    .economy-package {
      color: #016097;
      background: rgba(255, 255, 255, 0.9);
      border: 2px solid #016097;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .business-package {
      color: white;
      background: #016097;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .first-class-package {
      color: white;
      background: #d0ac51;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn {
      bottom: -20px;
      left: 10%;
      width: 80%;
      font-weight: 600;
      text-transform: uppercase;
      padding-top: 8px;
      padding-bottom: 8px;

      &.btn-economy {
        background: #2352ec;
        color: white;
        &:hover {
          background: white;
          border: 1px solid #2352ec;
          color: #2352ec;
        }
      }
      &.btn-business {
        background: grey;
        color: white;
        &:hover {
          background: white;
          border: 1px solid grey;
          color: grey;
        }
      }
      &.btn-first-class {
        background: #d0ac51;
        &:hover {
          background: white;
          border: 1px solid #d0ac51;
          color: #d0ac51;
        }
      }
    }
  }
  .card {
    .benefits-container {
      overflow-y: hidden;

      &.list-restricted {
        max-height: 320px;
      }

      p {
        margin-bottom: 5px !important;
      }

      .position-relative {
        font-size: 13px;
      }
    }
  }
}
.showmore {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;

  &.showmore-economy {
    background-color: white;
  }
  &.showmore-business {
    background-color: #016097;
  }
  &.showmore-firstclass {
    background-color: #d0ac51;
  }
}