.MyProductsListingPage {
  .top-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid;
    padding-bottom: 15px;
  }
  .product-list-item {
  }
}