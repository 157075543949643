.MyCustomers {
  .my-customer-wrapper {
    .nav-tabs {
      display: flex;
      //justify-content: space-between;
      .nav-item {
        width: 25%;
        color: black;
        text-align: center;
        &:hover {
          border-width: 0 0 2px 0;
        }
      }
      .nav-item.active {
        border-width: 0 0 2px 0;
        border-color: black;
        transition: all 0.4s ease-in;
        font-weight: bold;
      }
    }
  }
}

.reacrSelectHScountry {
  border-bottom: 1px solid gray;
  div {
    border: none !important;
  }
}