.step-wizard {
  .accordion {
    .card-header {
      max-height: 100px;
      .toggle-icon {
        position: absolute;
        left: 20px;
        top: 25px;
        color: black;
        transform: rotate(180deg);
        transition: all 0.3s ease-in;
        &.rotate-icon {
          transform: rotate(360deg);
          transition: all 0.3s ease-in;
        }
      }
    }
  }
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(180deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(180deg); }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
    transform:rotate(180deg);
  }
}
