.jumpseat-registration-container {
  #pointer {
    width: 18%;
    height: 40px;
    position: relative;
    background: grey;
    margin-left: 20px;
    opacity: 0.7;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid white;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
    &:before {
      content: "";
      position: absolute;
      right: -20px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 20px solid grey;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
    //&:first-of-type {
    //  &:after {
    //    content: none;
    //  }
    //}
    //&:last-of-type {
    //  &:before {
    //    content: none;
    //  }
    //}
    &.active {
      background: black;
      &:before {
        border-left: 20px solid black;
      }
    }
    label {
      color: white;
      margin-left: 26px;
      margin-top: 9px;
    }
    .check-icon {
      float:right;
      margin-top: 12px;
      color: white;
      margin-right: 5px;
    }
  }
}
