.JumpSeatMatchesContainer {
  #overlay {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(6px);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  @supports not ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    #overlay {
      background-image: url('../assets/images/backdrop-2.png');
      background-size: cover;
      top: -2px;
    }
  }
  input.register-btn {
    position: absolute;
    width: 150px;
    background: #016097;
    padding: 10px 20px;
    color: white;
    border-radius: 25px;
    margin-left: 50%;
    transform: translate(-50%);
    top: 40px;
    z-index: 9;
    &:hover {
      background: white;
      color: #016097;
      border: 1px solid #016097;
    }
  }
	.spinner-stuff {
		margin-top: 250px !important;
	}
}

.JumpseatMatchesFallbackContent {
  background: url("../assets/images/JumpseatFallback.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -20%;
  height: 100vh;
}

.MyMatchesContainer {
  #overlay {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(6px);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .agent-btn {
    position: absolute;
    width: 150px;
    background: black;
    padding: 10px 20px;
    color: white;
    border-radius: 25px;
    margin-left: 50%;
    transform: translate(-50%);
    top: 40px;
    z-index: 9;
    &:hover {
      background: white;
      color: black;
      border: 1px solid black;
    }
  }
  @supports not ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    #overlay {
      background-image: url('../assets/images/backdrop-2.png');
      background-size: cover;
      top: -2px;
    }
  }
}
