.footer-container {
  height: 100vh;
  background-color: #555555;
  color: white;
  overflow: hidden;
  
  img {
    margin-top: 20px;
    width: 110px;
  }
  p {
    font-size: 19px;
    span {
      color: #c6c6c6;
    }
  }
  p:last-of-type {
    position: absolute;
    bottom: 20px;
    margin-left: 50%;
    transform: translate(-53%);
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
}
