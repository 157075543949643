.NewBlogModal {
  .editor :global(.public-DraftEditor-content) {
    max-height: 200px;
    overflow: auto;
  }
  .file-upload-container {
    position: relative;
    text-align: center;
    border: none;
    outline: 2px dashed #ebebeb;
    background-color: #fcfcfc;
    -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;

    p.title {
      font-size: 1em;
      color: #444444;
    }

    p.sub-title {
      font-size: 0.75em;
      color: #444444;
    }

    .file-upload-content {
      position: absolute;
      top: 23px;
      left: 80px;
      right: 80px;
      pointer-events: none;

      svg {
        path {
          color: #444444;
        }
      }

      h4 {
        font-weight: 800;
      }
      h4,
      h6,
      small {
        color: #444444;
      }
    }

    .choose-file-input {
      color: #3a75c1;
      border: none;
      outline: 1px dashed #007bff;
      background-color: #f1fbff;
      -webkit-transition: outline-offset 0.15s ease-in-out,
      background-color 0.15s linear;
      transition: outline-offset 0.15s ease-in-out,
      background-color 0.15s linear;
      padding: 185px 0px 12px 23%;
      text-align: center !important;
      margin: 0;
      width: 100% !important;
      cursor: pointer;
      opacity: 0;
      &:focus {
        outline: 2px dashed #007bff;
        outline-offset: -10px;
        -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
        transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
        border: 1px solid #007bff;
        color: #6c757d;
      }
    }

    .choose-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }

    .file-selected {
      opacity: 0;
    }

    .choose-file-btn {
      position: absolute;
      bottom: 39px;
      left: 75px;
      right: 80px;
      pointer-events: none;
      font-weight: 600;

      button {
        color: #444444;
      }
    }
  }
}
