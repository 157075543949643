.wlr-title {
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
}
.wlr-subtitle {
  font-size: 20px;
  font-weight: 300;
}
.login-container,
.jumpseat-login-container,
.ResetPasswordContainer {
  form {
    margin-left: 50%;
    transform: translate(-50%);
    .floating-fields {
      .field {
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 1em;
      }
      label,
      input {
        transition: all 0.2s;
        touch-action: manipulation;
        font-size: 14px;
      }

      input {
        border: 0;
        border-bottom: 1px solid #ccc;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
        background: transparent;
      }

      input:focus {
        outline: 0;
        border-bottom: 1px solid #666;
      }

      label {
        letter-spacing: 0;
        font-size: 9px;
        text-transform: uppercase;
        padding-bottom: 6px;
        opacity: 0.3;
      }
      input:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125rem) scale(1.5);
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
      }
      input:focus::-webkit-input-placeholder {
        opacity: 1;
      }
      input:not(:placeholder-shown) + label,
      input:focus + label {
        transform: translate(0, 0) scale(1);
        cursor: pointer;
      }
    }
    .forgot-password {
      font-size: 12px;
    }
  }
}

.login-container {
  .page-header {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    img {
      top: 0;
      width: 100%;
      position: absolute;
      height: 50vh;
    }
  }

  &.loginPage {
    margin-bottom: 0px;
    padding-top: 0;
    height: 100vh;

    .page-header {
      height: 50vh;
    }

    .autorize {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    form {
      margin-left: 0;
      transform: none;
    }
  }
}
