.PaymentPageContainer {
  .payment-card {
    padding: 100px 50px;
    text-align: left;
  }
  .spinner {
    position: fixed;
    top: 45vh;
    left: 50%;
    z-index: 12;
  }
  #overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 11;
  }
}