.slideInMenu-container {
  .closeBtn {
    &:hover {
      opacity: .5;
    }
  }
  .slidein-menu  {
    .list-group-item {
      padding: 0;

      h5 {
        margin-bottom: 0;

        a, span {
          display: block;
          padding: 10px 15px;
          font-weight: 300;
          color: black;

          &:hover {
            background: #f2f2f2;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
    }
  }
  .contact-card {
    padding: 20px;
    height: 120px;
    overflow: hidden;
    position: absolute;
    bottom: 280px;
    right: 20px;
    left: 20px;

    img {
      max-height: 100px;
    }
    p {
      font-size: 12px;
    }
  }
}
