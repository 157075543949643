.CompanyProfile {
  .editorClassName {
    border: 1px solid #cecece;
  }
  .upload-hero-image-btn {
    padding: 4px 13px 6px;
    background: rgba(0, 0, 0, 0.4);
    right: 20px;
    bottom: 20px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.8);

      svg {
        path {
          color: white;
        }
      }
    }

    svg {
      height: 16px;

      path {
        color: #cecece;
      }
    }
  }
  .company-description {
    .company-description-box {
      padding: 7.5px 15px 15px;
      
      .company-description-content {
        margin-top: 10px;
        margin-bottom: 60px;

        p {
          padding: 0;
          margin: 0;
          line-height: 1.6;
          font-size: 14px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
  .company-profile-image-box {
    padding: 20px 20px;
    margin-bottom: 20px;

    .company-image-box {
      margin: 0;
    }
    
    .company-profile-image {
      display: block;
      width: 100%;
      object-fit: contain;
      padding: 0 60px;
      border-radius: 3px;
    }
  }
  .company-profile-box {
    padding: 7.5px 15px 15px;
    margin-bottom: 20px;

    .company-profile-title {
      display: block;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 19px;
    }
    .company-profile-location {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0px;
    }
    .company-contact-image {
      max-height: 84px;
      width: 100%;
      object-fit: cover;
    }
    .imgCenter {
      justify-content: center;
    }
    .company-contact-list {
      padding-left: 0;

      .company-contact-list-name {
        font-size: 18px;
        line-height: 18px;
        margin: 0;
      }
      .company-contact-list-mobile,
      .company-contact-list-phone,
      .company-contact-list-fax {
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
.product-services {
  padding: 20px 45px 10px 25px;

  .product-services-card {
    border-width: 0;
    border-radius: 0;

    .card-body {
      padding: 0;

      img {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
      }
      p {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
}
.company-profile-modal,
.HeroImageModal,
.CompanyServicesModal {
  .file-upload-container {
    position: relative;
    text-align: center;
    border: none;
    outline: 2px dashed #ebebeb;
    background-color: #fcfcfc;
    -webkit-transition: outline-offset 0.15s ease-in-out,
      background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;

    p.title {
      font-size: 1em;
      color: #444444;
    }

    p.sub-title {
      font-size: 0.75em;
      color: #444444;
    }

    .file-upload-content {
      position: absolute;
      top: 23px;
      left: 80px;
      right: 80px;
      pointer-events: none;

      svg {
        path {
          color: #444444;
        }
      }

      h4 {
        font-weight: 800;
      }
      h4,
      h6,
      small {
        color: #444444;
      }
    }

    .choose-file-input {
      color: #3a75c1;
      border: none;
      outline: 1px dashed #007bff;
      background-color: #f1fbff;
      -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
      transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
      padding: 185px 0px 12px 23%;
      text-align: center !important;
      margin: 0;
      width: 100% !important;
      cursor: pointer;
      opacity: 0;
      &:focus {
        outline: 2px dashed #007bff;
        outline-offset: -10px;
        -webkit-transition: outline-offset 0.15s ease-in-out,
          background-color 0.15s linear;
        transition: outline-offset 0.15s ease-in-out,
          background-color 0.15s linear;
        border: 1px solid #007bff;
        color: #6c757d;
      }
    }

    .choose-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }

    .file-selected {
      opacity: 0;
    }

    .choose-file-btn {
      position: absolute;
      bottom: 39px;
      left: 75px;
      right: 80px;
      pointer-events: none;
      font-weight: 600;

      button {
        color: #444444;
      }
    }
  }
  .file-upload-container-contact-person {
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    border: none;
    outline: 2px dashed #ebebeb;
    background-color: #fcfcfc;
    -webkit-transition: outline-offset 0.15s ease-in-out,
      background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;

    p.title {
      font-size: 1em;
      color: #444444;
    }

    p.sub-title {
      font-size: 0.85em;
      color: #444444;
    }

    .file-upload-content {
      position: absolute;
      top: 23px;
      left: 0px;
      right: 80px;
      pointer-events: none;
      width: 100%;
      text-align: center;

      svg {
        path {
          color: #444444;
        }
      }
    }

    .choose-file-input {
      color: #3a75c1;
      border: none;
      outline: 1px dashed #007bff;
      background-color: #f1fbff;
      -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
      transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
      padding: 105px 0px 12px 23%;
      text-align: center !important;
      margin: 0;
      width: 100% !important;
      cursor: pointer;
      opacity: 0;
      &:focus {
        outline: 2px dashed #007bff;
        outline-offset: -10px;
        -webkit-transition: outline-offset 0.15s ease-in-out,
          background-color 0.15s linear;
        transition: outline-offset 0.15s ease-in-out,
          background-color 0.15s linear;
        border: 1px solid #007bff;
        color: #6c757d;
      }
    }

    .choose-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }

    .file-selected {
      opacity: 0;
    }

    .choose-file-btn {
      position: absolute;
      bottom: 39px;
      left: 75px;
      right: 80px;
      pointer-events: none;
    }
  }
}
