nav.navbar {
  &.nav-moving {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
  }
  &.page-navbar {
    position: fixed;
    width: 100%;
    z-index: 11;
  }
  a.nav-link {
    color: black !important;
  }
  .nav-search {
    margin-left: 100%;
    transform: translate(-50%);
    border-radius: 0 !important;
    outline: none;

    &:focus {
      box-shadow: none;

      &.regular {
        border-color: black;
      }
      &.gold {
        border-color: #d0ac51;
      }
    }
  }
  .navbar-search-btn {
    position: absolute;
    right: -228px;
  }

  &:hover {
    .nav-search {
      &.regular {
        border-color: black;
      }
      &.gold {
        border-color: #d0ac51;
      }
    }
  }
}
.wlr-logo {
  position: absolute;
  background-color: white;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  &.logo-restricted {
    top: -20px;
    height: 38px;
    width: auto;
  }
  &.logo-expanded {
    padding: 2rem 1rem 1rem;
  }
}