.jumpseat-member {
  &.add-more-btn {
    //bg
    color: #fff;
    //bg
    border-color: #fff;
    &:hover {
      //bg
      background-color: #fff;
      //font
      color: #000;
    }
  }
  &:focus, &:hover {
    border-color: #000 !important;
  }
}

.economy-member {
  &.add-more-btn {
    //bg
    color: #fff;
    //bg
    border-color: #fff;
    &:hover {
      //bg
      background-color: #fff;
      //font
      color: #1e345a;
    }
  }
  &:focus, &:hover {
    border-color: #fff !important;
  }
}

.business-member {
  &.add-more-btn {
    //bg
    color: #1e345a;
    //bg
    border-color: #1e345a;
    &:hover {
      //bg
      background-color: #1e345a;
      //font
      color: #fff;
    }
  }
  &:focus, &:hover {
    border-color: #1e345a !important;
  }
}

.first-class-member {
  &.add-more-btn {
    //bg
    color: #d0ac51;
    //bg
    border-color: #d0ac51;
    &:hover {
      //bg
      background-color: #d0ac51;
      //font
      color: #fff;
    }
  }
  &:focus, &:hover {
    border-color: #d0ac51 !important;
  }
}

.founding-member {
  &.add-more-btn {
    color: #000;
    border-color: #000;
    &:hover {
      background-color: #000;
      color: #FFF;
    }
  }
  &:focus, &:hover {
    border-color: #000 !important;
  }
}

.admin-member {
  &.add-more-btn {
    //bg
    color: #4C0013;
    //bg
    border-color: #4C0013;
    &:hover {
      //bg
      background-color: #4C0013;
      //font
      color: #C2B59B;
    }
  }
  &:focus, &:hover {
    border-color: #4C0013 !important;
  }
}

.agent-member {
  &.add-more-btn {
    //bg
    color: #C3C3C3;
    //bg
    border-color: #C3C3C3;
    &:hover {
      //bg
      background-color: #C3C3C3;
      //font
      color: #000;
    }
  }
  &:focus, &:hover {
    border-color: #000 !important;
  }
}
