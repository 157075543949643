.MyClientContainer {
  .page-header {
    background: url("../assets/images/search.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.SearchResultPage, .NotFoundContainer, .BlogContainer {
  .page-header {
    background: url("../assets/images/newsfeed.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 100%;
  }
}

.jumpseat-registration-container, .jumpseat-login-container, .AdminPageContainer {
  .page-header {
    background: url("../assets/placeholders/JS_4_step_HERO.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 100%;
  }
}

.NewInviteModal {
  text-align: center;
  &.modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

.JumpseatInviteContainer {
  .page-header {
    background: url("../assets/images/jumpseatinvt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.MyMatchesContainer, .JumpSeatMatchesContainer {
  .page-header {
    background: url("../assets/images/mymatches.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 30%;
  }
}

.PaymentPageContainer, .SalesPageContainer {
  .page-header {
    background: url("../assets/images/mymatches.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 30%;
  }
}

.ResetPasswordContainer {
  .page-header {
    background: url("../assets/images/login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 30%;
  }
}
.MyCustomers {
  .page-header {
    background: url("../assets/images/My_Customer_Section_Headline_image.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 30%;
  }
}
