.MaintenanceContainer {
  background: url("../assets/images/untld-bg.png");
  background-size: contain;
  .content-wrapper {
    form {
      border-radius: 4px;
      background-color: transparent;
      //padding: 20px 20px;
      margin-left: 50%;
      transform: translate(-50%);
      margin-top: 160px;
      input[type="password"] {
        background-color: transparent;
        color: white;
        border-bottom: 2px solid white;
      }
    }
  }
}
