body,
html {
  $box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.07);
  $box-bg: white;
  overflow-x: hidden;
}

body > iframe {
  display: none !important;
}

@mixin box {
  background-color: white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.07);
}

.overfloy-media {
  @media only screen and (max-height: 720px) {
    scrollbar-width: auto;
  } 
  @media only screen and (max-height: 720px) {
    max-height: 230px;
    overflow: auto;

  }
 
 @media only screen and (max-height: 625px) {
    max-height: 130px;

}
}

.overfloy-media::-webkit-scrollbar {
  @media only screen and (max-height: 720px) {
    width: 10px!important;
    background: #e9e9e9;
    display: block; 
    scrollbar-width: auto !important;
  }  
}

.overfloy-media::-webkit-scrollbar-thumb {
  @media only screen and (max-height: 720px) {
    background: #a9a4a4;
    scrollbar-width: auto !important;
  }  
}

.overfloy-media::-webkit-scrollbar {
  @media only screen and (max-height: 720px) {
    scrollbar-width: auto !important;
  }
}
@-moz-document url-prefix() {
  // .overfloy-media::-webkit-scrollbar {
  // scrollbar-width: 10px !important;
  // }
  // .overfloy-media {
  //   scrollbar-width: 10px !important;
  //   }
  .overfloy-media::-webkit-scrollbar {
    @media only screen and (max-height: 720px) {
      * {
        scrollbar-width: auto !important;
      }
      scrollbar-width: auto !important;
      width: 10px !important;
      background: #e9e9e9;
      display: block; 
    }  
  }
  
  .overfloy-media::-webkit-scrollbar-thumb {
    @media only screen and (max-height: 720px) {
      background: #a9a4a4;
    }  
  }
  
  // .overfloy-media::-webkit-scrollbar {
  //   @media only screen and (max-height: 720px) {
  //     // width: 20px;
  //   }
  // }
}

@supports (-moz-appearance:none) {
  .overfloy-media::-webkit-scrollbar {
    @media only screen and (max-height: 720px) {
      width: 10px!important;
      background: #e9e9e9;
      display: block; 
    }  
  }
  
  .overfloy-media::-webkit-scrollbar-thumb {
    @media only screen and (max-height: 720px) {
      background: #a9a4a4;
    }  
  }
  
  .overfloy-media::-webkit-scrollbar {
    @media only screen and (max-height: 720px) {
    }
  }
}

.paginate_button {
  &:hover {
    background: none !important;
    border: none !important;
  }
}

body {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.page-container {
  padding-top: 56px;
  margin-bottom: 200px;
  min-height: 80vh;
}
.box {
  background-color: white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.07);
}
.btn {
  padding: 5px 15px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  border-width: 1px;
}

.testqwe {
  >div {
  overflow: hidden;
  
    img {
      max-height: 175px;
      max-width: 230px;
    }
   
  }
}
.btn-wlr {
  background-color: #3a75c1;
  color: white;
  min-width: 140px;
  border: 1px solid;

  &:hover {
    background-color: #18519b;
    color: white;
  }
}
.btn-wlr-inverse {
  background-color: white;
  color: #3a75c1;
  border: 1px solid #3a75c1;
  &:hover {
    background-color: #3a75c1;
    color: white;
  }
}

.btn-dark-inverse {
  background-color: white;
  color: black;
  border: 1px solid black;
  &:hover {
    background-color: black;
    color: white;
  }
}

.btn-gold-inverse {
  background-color: white;
  color: rgb(208, 172, 81);
  border: 1px solid rgb(208, 172, 81);
  &:hover {
    background-color: rgb(208, 172, 81);
    color: white;
  }
}
.section-title {
  display: inline-block;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;

  svg {
    height: 16px;
    width: 16px !important;
    padding-bottom: 3px;
    margin-left: 5px;
    border: 1px solid #3b75c0;
    color: #3b75c0;
    padding: 2px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background: #3b75c0;
      color: white;
    }
  }
}
.seperator {
  margin-top: 15px;
  margin-bottom: 15px;
}
.subsection {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  margin-top: -7px;
}
.company-address-list {
  margin-bottom: 0;
  font-size: 14px;
}
.footercard {
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 30px;

  img {
    max-width: 100%;
  }

  h3 {
    font-weight: 800;
    letter-spacing: -0.25px;
    line-height: 24px;
  }
  p {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: normal;
    line-height: 18px;
    max-width: 90%;
    color: rgba(0, 0, 0, 0.4);
  }
}
.modal-dialog {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.modal-content {
  border-radius: 0;
  border-width: 0;

  .modal-header {
    align-items: center;
    padding: 10px 20px;
    border-radius: 0 !important;
    border-width: 0;
    background-color: #016097;
    color: white;

    &.membership-header-yellow {
      background-color: white;
    }

    &.membership-header-white {
      background-color: white;
    }

    &.membership-header {
      text-align: center;
      padding-top: 10px;
      margin-bottom: 30px;
      position: relative;
      display: block;
      color: black;
      padding-top: 30px;

      h3 {
        padding-bottom: 5px;
        margin-bottom: 10px;
        color: black;
        font-weight: 800;
        font-size: 24px;

        &:after {
          display: block;
          margin: 0 auto;
          margin-top: 10px;
          content: "";
          width: 90px;
          height: 2px;
          background-color: black;
        }
      }
      p {
        font-size: 18px;
      }
      .close {
        padding-top: 0;
        padding-bottom: 0;
        color: black;
        opacity: 0.75;
        text-shadow: none;
        position: absolute;
        top: 20px;
        right: 10px;
      }
    }
    .close {
      padding-top: 0;
      padding-bottom: 0;
      //margin-top: -60px;
      color: black;
    }
  }

  .close {
    color: white;
    text-shadow: none;
    opacity: 0.75;
  }

  h3 {
    margin: 0;
    font-size: 16px;
  }
}

.modal-body {
  font-size: 14px;

  label {
    margin-top: 10px;
    max-width: 60%;
    line-height: 16px;
  }
  textarea {
    margin-top: 10px;
  }
}
.modal-footer {
  border-width: 0;
  padding-top: 5px;

  .btn-dark {
    background-color: #3a75c1;
    border-color: #3a75c1;
    color: white;

    &:hover {
      background-color: #18519b;
      color: white;
    }
  }
}

.form-control {
  padding: 0.3rem 0.25rem;
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  color: rgba(0, 0, 0, 0.4);

  &:focus {
    border-bottom: 2px solid #3a75c1;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.6);
    border-bottom: 2px solid #3a75c1;
    cursor: pointer;
  }

  &.form-control-black {
    &:hover,
    &:focus {
      border-color: black;
    }
  }
  &.form-control-gold {
    &:hover,
    &:focus {
      border-color: rgb(208, 172, 81);
    }
  }
}
textarea.form-control {
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:focus {
    border: 2px solid #3a75c1;
  }

  &:hover {
    border: 2px solid #3a75c1;
  }
}
.add-more-btn {
  margin: 0 auto;
  margin-top: 10px;
  max-width: 60px;
  //color: white;
  cursor: pointer;
  border-radius: 8px;
  font-size: 10px;
  height: 60px;
  border-radius: 50%;
  line-height: 73px;
  color: #3a75c1;
  border: 2px solid #3a75c1;

  &:hover {
    background-color: #3a75c1;
    color: white;
  }
}
.topsearch-container {
  max-width: 600px;
  h1 {
    bottom: 45px;
    position: absolute;
    color: white;
    font-size: 28px;
    font-weight: 700;
  }
  button {
    //style={{position: "absolute", right: "20px", top: "4px"}}
    position: absolute;
    right: 20px;
    top: 12px;
  }
}
.topsearch {
  max-width: 600px;
  margin: 0 auto;
  border-radius: 3px;
  text-indent: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.17);
  height: 50px;
}
.properties {
  font-size: 12px;
  label {
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;

    &.w-100 {
      font-weight: 800;
    }
  }
}
.newsfeed {
  @include box();
  margin-top: 30px;
  &.card {
    border-width: 0;
  }
  header.newsfeed-header {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #ebebeb;
    background-color: #d1d1f0;

    img {
      margin-right: 1em;
      height: 40px;
      width: 80px;
      object-fit: cover;
    }
    h3 {
      font-size: 20px;
    }
  }
  .newsfeed-comments-likes {
    div:last-of-type {
      cursor: pointer;
    }
  }
}
.CommentWrapper {
  .comment-item {
    @include box();
    margin-top: 5px;
    background: #fafafa;
  }
}
footer.newsfeed-footer {
  padding: 7.5px 15px;
  border-top: 1px solid #ebebeb;

  .newsfeed-footer_list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    .newsfeed-footer_element {
      padding: 3px 8px;

      &:not(:last-child) {
        margin-right: 1em;
      }

      &:hover {
        @include box();
        border-radius: 3px;
        cursor: pointer;
      }
      svg {
        margin-right: 0.5rem;
        font-size: 14px;
      }
      span {
        font-size: 14px;
      }
    }
  }
}
.agent-match-search {
  &:first-child {
    background-color: white;
    animation: Pulse 3s infinite;
  }
}

.map-marker-match-company {
  background-color: white;
  animation: Pulse 3s infinite;
}

@-webkit-keyframes Pulse {
  0% {
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.3),
      0 3px 3px rgba(0, 0, 0, 0.07), 0 0 14px rgba(0, 0, 0, 0.05);
  }
  50% {
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.3),
      0 3px 3px rgba(0, 0, 0, 0.07), 0 0 14px rgba(0, 0, 0, 0.35);
  }
  100% {
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.3),
      0 3px 3px rgba(0, 0, 0, 0.07), 0 0 14px rgba(0, 0, 0, 0.05);
  }
}

@keyframes Pulse {
  0% {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.07),
      0 0 14px rgba(0, 0, 0, 0.05);
  }
  50% {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.07),
      0 0 14px rgba(0, 0, 0, 0.35);
  }
  100% {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.07),
      0 0 14px rgba(0, 0, 0, 0.05);
  }
}

.WelcomeModal {
  .DemoStep {
    min-height: 500px;
  }
  .pagination {
    place-content: center;

    .left-arrow,
    .right-arrow {
      cursor: pointer;
      border: none;

      &:hover {
        background-color: #fffe;
      }
    }

    li.page-item {
      a.page-link {
        font-size: 0;
        color: transparent;
        border-radius: 15px;
        padding: 0px;
        width: 8px;
        height: 8px;
        margin: 4px;
      }

      a.columns {
        background-color: #eaeaea;
      }

      a.active {
        background-color: #0ba9e0 !important;
        border-color: transparent;
      }
    }
  }
}
span.select-info {
  display: block;
}

.product-services {
  .slick-dots {
    position: unset;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(38, 31, 28, 0.7);
  //opacity: 0.5;
  left: 0;
  top: 0;
  z-index: 1;
  color: white;
  display: flex;
  align-items: center;
}

.remove-focus {
  &:focus {
    border: none;
    outline: none;
  }
}

.CookieConsent {
  div:first-child {
    margin: 15px;
  }
  div:first-child,
  div:last-child {
    //width: fit-content;
    //float: left;
    display: inline;
  }
  position: fixed;
  background: #363839;
  color: #8c8989;
  text-align: center;
  width: 100%;
  z-index: 999;
}

.name-initials {
  font-size: 1.5em;
  font-weight: 600;
  color: #fff;
  background: #245cca;
  text-align: center;
  line-height: 82px;
  width: 80px;
  height: 80px;
  margin: 0;
  border-radius: 100%;
  //margin-left: 5px;
  margin-right: 15px;
  cursor: pointer;
}

.WelcomeVideoModal {
  .modal-content {
    //background: transparent;
  }
  .modal-dialog {
    //box-shadow: none;
  }
}

.mytooltip {
  .tooltip-inner {
    width: 500px;
  }
}
.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-yk16xz-control {
  border-bottom: 1px;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.TOCPage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  //height: 400px;
  overflow: scroll;
  width: 100%;
  z-index: 100;
  border-radius: 10px;
  //left: 20px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  button {
    //margin-left: 50%;
    //transform: translate(-50%);
    margin-bottom: 50px;
    margin-left: 20px;
  }
  img {
    margin-left: 50%;
    transform: translate(-50%);
    margin-bottom: 20px;
  }
}

.input-error {
  border-color: red !important;
}

.custom-checkbox-dkpns {
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: rgba(0, 0, 0, 0.5);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 9px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  p.the-label {
    margin-bottom: 0;
    margin-top: 7px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
  }
}

.SalesPageContainer {
  #table_id {
    width: 100% !important;
  }
}

.unseen-match-frame {
  border: 4px solid #d0ac51;
  .new-match-label {
    position: absolute;
    right: -2px;
    top: 12px;
    z-index: 10;
    background: #d0ac51;
    padding: 2px 5px;
    color: white;
  }
}
.slick-prev:before,
.slick-next:before {
  color: black;
}

.dropdown-content {
  z-index: 9999 !important;
  position: absolute !important;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  // @media only screen and (max-height: 720px) {
  //   scrollbar-width: auto;
  // } 
}

.breadcrumb-item {
  padding-left: 0 !important;
  &:before {
    content: none !important;
  }
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.search-offer-filter {
  background: rgba(0, 0, 0, 0.03);
}

.categoriesFilterFixed {
  border-top: 1px solid #032469;
  border-left: 1px solid #032469;
  border-right: 1px solid #032469;
}

.innerListCategories {
  background: #d9dee9;
  color: #032469;
  border-top: 1px solid #032469;
}

.rightBlockAddNewProduct {
  margin-right: 30px;
  align-self: flex-start;
  &-upload {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      margin: 0 15px 0 0;
    }
  }

  &-download {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;

    p {
      margin: 0 15px 0 0;
    }

    a {
      color: initial;
    }
  }
}

.middleBlockAddNewProduct {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #ff0000;
    margin-top: 10px;
  }
}

.MyProductsListingPage .top-navbar.addNewProductTopNavBar {
  justify-content: space-between;
}

// .adminTable {
//   &-body {
//     & > tr > td:nth-child(4) {
//       background: red;
//       -ms-text-overflow: ellipsis;
//       text-overflow: ellipsis;
//       overflow: hidden;
//       -ms-line-clamp: 2;
//       -webkit-line-clamp: 2;
//       line-clamp: 2;
//     }
//   }
// }
